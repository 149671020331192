<template>
  <nav class="sidebar" :class="sidebarTheme">
    <ul class="nav mt-2 mb-4 d-flex">
      <li
        v-if="myOrganizations.length > 1"
        class="nav-item nav-organization-selector"
      >
        <div class="mb-3">
          <b-form-select
            v-model="selectedOrganization"
            :options="organizationsOptions"
            :disabled="disableSelect"
            size="sm"
            @change="switchOrganization"
          ></b-form-select>
        </div>
      </li>
      <li v-if="showBranch" class="nav-item nav-organization-selector">
        <div class="mb-3">
          <b-form-select
            v-model="selectedBranch"
            :options="branchOptions"
            :disabled="disableSelect"
            size="sm"
            @change="switchBranch"
          ></b-form-select>
        </div>
      </li>

      <!-- <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'DashboardHome' }">
            <i class="menu-icons"></i>
            <span class="menu-title">主介面</span>
          </router-link>
        </li> -->

      <template v-for="i in sidebarMap">
        <li
          v-if="
            i.items.length > 0 &&
            (i.checkPermissionAny
              ? checkPermissionAny(i.checkPermissionAny)
              : i.checkPermission
              ? checkPermission(i.checkPermission)
              : true)
          "
          class="nav-item"
          :style="{ order: sidebarOrder(i.key) || i.order }"
          :key="i.key"
        >
          <a class="nav-link" v-b-toggle="`${i.key}`">
            <icon :src="i.icon" />
            <!-- <i class="menu-icons"></i> -->
            <span class="menu-title">{{ i.text }}</span>
            <i class="menu-arrow"></i>
          </a>
          <b-collapse :id="i.key" :visible="subMenuVisible(i)">
            <ul class="nav flex-column sub-menu">
              <template v-for="(j, jdx) in i.items">
                <li
                  v-if="
                    j.checkPermissionAny
                      ? checkPermissionAny(j.checkPermissionAny)
                      : j.checkPermission
                      ? checkPermission(j.checkPermission)
                      : true
                  "
                  class="nav-item"
                  :key="jdx"
                >
                  <router-link
                    class="nav-link"
                    :class="subMenuActive(j) && 'active'"
                    :to="j.to"
                    >{{ j.text }}</router-link
                  >
                </li>
              </template>
            </ul>
          </b-collapse>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script lang="js">
import { mapState, mapActions, mapGetters } from 'vuex'
import PermissionChecker from '@/utils/PermissionChecker'
import { displayText } from '@/utils/dashboardUtils'
import * as consts from '@/consts'
import Vue from "vue";
import bookingApi from "@/apis/booking";

const requireImage = (path) =>
  require(`@/assets/images/sidebar/${path}`);


const icon = Vue.component("icon", {
  props: ["src"],
  render(h) {
    return h("img", {
      class: "menu-icons",
      style: {
        maskImage: this.getIconMask(this.$props.src),
        webkitMaskImage: this.getIconMask(this.$props.src),
      },
    });
  },
  methods: {
    getIcon(path) {
      try {
        return requireImage(path);
      } catch {
        return "";
      }
    },
    getIconMask(src) {
      return `url(${this.getIcon(src)})`;
    },
  },
});


export default {
  name: 'app-sidebar',
  props: ['themeConfig'],
  components: {
    icon,
  },
  data() {
    return {
      disableSelect: false,
      selectedBranch: null,
      selectedOrganization: null,
      consts: consts,
      bookingPresets: [],
    }
  },
  mounted() {
    this.init()

    // 設定 Dashboard 的 Title
    if (this.themeConfig.og_title !== null || this.themeConfig.og_title !== undefined) {
      document.title = this.themeConfig.og_title;
    } else {
      document.title = "";
    }

    // 設定 Dashboard 的 favicon
    if (this.themeConfig.og_favicon_url !== null || this.themeConfig.og_favicon_url !== undefined) {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = this.themeConfig.og_favicon_url;
    }
  },
  computed: {
    ...mapState('general', {
      myBranches: state => state.myBranches,
      currentBranch: state => state.currentBranch,
      myOrganizations: state => state.myOrganizations,
      organization: state => state.organization,
      sidebarTheme: (state) => state.sidebarTheme,
      dashboardModule: (state) => state.dashboardModule
    }),
    ...mapGetters('general', ['role', 'branchOptions']),
    organizationsOptions() {
      return this.myOrganizations.map(organization => {
        return {
          value: organization.id,
          text: organization.name
        }
      })
    },
    showBranch() {
      return this.branchOptions.length > 0 && this.checkPermission([consts.MY_SIDEBAR_BRANCH])
    },
    // showOrgSetting() {
    //   return this.checkPermission([consts.ORGANIZATION_VARIABLE]) ||
    //     this.checkPermission([consts.ROLE_LIST_VIEW]) ||
    //     this.checkPermission([consts.MODULE_LIST_VIEW]) ||
    //     this.checkPermission([consts.KEYWORD_LIST_VIEW]) ||
    //     this.checkPermission([consts.SQL_RUNNER_VIEW]) ||
    //     this.checkPermission([consts.MERCHANT_LIST_VIEW]) ||
    //     this.checkPermission([consts.LOGS_LIST_VIEW]) ||
    //     this.checkPermission([consts.TOKEN_LIST_VIEW])
    // },
    sidebarMap() {
      return [
        {
          key: "sidebar-my-console",
          text: "我的主控台",
          order: 100,
          logic: 'OR',
          icon: 'gauge-solid.svg',
          checkPermissionAny: [
            consts.MY_OVERVIEW,
            consts.CHATROOM_LIST_CHAT_VIEW,
            consts.MY_CUSTOMER_LIST,
            consts.MY_REDEEM_CODE_LIST_VIEW,
            consts.MY_DEPT_CUSTOMER_LIST,
            consts.MY_EXPORT
          ],
          items: [
            { to: { name: 'Overview' }, text: '系統總覽', checkPermission: [consts.MY_OVERVIEW] },
            { to: { name: 'ChatroomIndex' }, text: '聊天室總覽', checkPermission: [consts.CHATROOM_LIST_CHAT_VIEW] },
            { to: { name: 'MyConsoleStaffCustomerList' }, text: '我的客戶', checkPermission: [consts.MY_CUSTOMER_LIST] },
            { to: { name: 'MyConsoleRedeemCodeBundleList' }, text: '我的優惠券', checkPermission: [consts.MY_REDEEM_CODE_LIST_VIEW] },
            { to: { name: 'MyConsoleBranchStaffCustomerList' }, text: '部門客戶', checkPermission: [consts.MY_DEPT_CUSTOMER_LIST] },
            { to: { name: 'MyConsoleDownloadFileList' }, text: '資料匯出', checkPermission: [consts.MY_EXPORT] },
          ]
        },
        {
          key: "sidebar-line-oa",
          text: "LINE 應用中心",
          icon: 'line-brands-solid.svg',
          order: 150,
          checkPermissionAny: [
            consts.OA_MESSAGE_VIEW,
            consts.OA_PUSH_VIEW,
            consts.LINE_MENU_LIST_VIEW
          ],
          items: [
            { to: { name: 'OAChatroomIndex' }, text: 'LINE 聊天', checkPermission: [consts.OA_MESSAGE_VIEW] },
            { to: { name: 'PushMessage' }, text: '群發訊息', checkPermission: [consts.OA_PUSH_VIEW] },
            { to: { name: 'PushMessageTemplate' }, text: '訊息範本', checkPermission: [consts.OA_PUSH_VIEW] },
            { to: { name: 'RichMenu' }, text: '圖文選單', checkPermission: [consts.LINE_MENU_LIST_VIEW] },
          ]
        },
        {
          key: "sidebar-customer",
          text: "會員管理中心",
          icon: 'users-gear-solid.svg',
          order: 200,
          checkPermissionAny: [consts.CUSTOMER_LIST_VIEW,
          consts.CUSTOMER_LEVEL_LIST,
          consts.CUSTOMER_LEVEL_RULE_LIST,
          consts.AUDIENCE_LIST_VIEW,
          consts.TAG_LIST_VIEW],
          items: [
            { to: { name: 'CustomerList' }, text: '會員管理', checkPermission: [consts.CUSTOMER_LIST_VIEW] },
            { to: { name: 'CustomerLevelsList' }, text: '會員等級管理', checkPermission: [consts.CUSTOMER_LEVEL_LIST] },
            { to: { name: 'CustomerLevelRuleList' }, text: '會員等級條件', checkPermission: [consts.CUSTOMER_LEVEL_RULE_LIST] },
            { to: { name: 'AudienceList' }, text: '受眾管理', checkPermission: [consts.AUDIENCE_LIST_VIEW] },
            { to: { name: 'TagList' }, text: '標籤管理', checkPermission: [consts.TAG_LIST_VIEW] },
          ]
        },
        {
          key: "sidebar-core_groupbuyings",
          text: "團購中心",
          icon: 'cart-shopping-solid.svg',
          order: 250,
          checkPermissionAny: [
            consts.CORE_GROUPBUYING_LIST_VIEW,
            consts.CORE_GROUPBUYING_CREATE,
            consts.CORE_GROUPBUYING_MODIFY,
            consts.CORE_GROUPBUYING_PRODUCT_LIST,
          ],
          items: [
            { to: { name: 'CoreGroupbuyingsListV2' }, text: '團購管理', checkPermission: [consts.CORE_GROUPBUYING_LIST_VIEW] },
            { to: { name: 'CoreGroupbuyingsList' }, text: '團購草稿', checkPermissionAny: [consts.CORE_GROUPBUYING_CREATE, consts.CORE_GROUPBUYING_MODIFY] },
            { to: { name: 'CoreGroupbuyingsProductList' }, text: '團購商品管理', checkPermissionAny: [consts.CORE_GROUPBUYING_PRODUCT_LIST] }
          ]
        },
        {
          key: "sidebar-partner",
          text: this.displayText('partner.partner_area', '合作夥伴專區'),
          icon: 'building-user-solid.svg',
          order: 300,
          checkPermissionAny: [consts.PARTNER_WORKER_LIST_VIEW, consts.PARTNER_LIST_VIEW, consts.PARTNER_STAFF_LIST_VIEW],
          items: [{ to: { name: 'PartnerList' }, text: this.displayText('partner.partner_management', '合作夥伴管理'), checkPermission: [consts.PARTNER_LIST_VIEW] },
          { to: { name: 'PartnerWorkerList' }, text: displayText('partner.partner_worker_management', '夥伴員工管理'), checkPermission: [consts.PARTNER_WORKER_LIST_VIEW] },
          { to: { name: 'PartnerWorkerListVerifying', query: { status: 'pending', page_type: 'verify' } }, text: this.displayText('partner.partner_worker_verify_page', '夥伴員工審核'), checkPermission: [consts.PARTNER_WORKER_VERIFY_LIST_VIEW] },
          { to: { name: 'PartnerStaffEntry' }, text: this.displayText('partner.partner_staff_list', '夥伴帳號列表'), checkPermission: [consts.PARTNER_STAFF_LIST_VIEW] },]
        },
        {
          key: "sidebar-data-chart",
          text: this.displayText("data_chart.title", "報表中心"),
          icon: 'chart-line-solid.svg',
          order: 350,
          checkPermissionAny: [consts.PARTNER_WORKER_REPORT_LIST],
          items: [{ to: { name: 'PartnerWorkerDataChart' }, text: this.displayText('data_chart.partner_worker_chart', '夥伴員工統計'), checkPermission: [consts.PARTNER_WORKER_REPORT_LIST] },
          { to: { name: 'PartnerWorkerJoinLineByMonthDataChart' }, text: displayText('data_chart.partner_worker_join_line_by_month_chart', '夥伴員工綁定統計'), checkPermission: [consts.PARTNER_WORKER_REPORT_LIST] },
          { to: { name: 'StaffByMonthDataChart' }, text: this.displayText('data_chart.staff_join_line_by_month_chart', '業務綁定統計'), checkPermission: [consts.PARTNER_WORKER_REPORT_LIST] },
          ]
        },
        {
          key: "sidebar-award",
          text: "獎勵應用中心",
          icon: 'trophy-solid.svg',
          order: 400,
          checkPermissionAny: [
            consts.REDEEM_CODE_LIST_VIEW,
            consts.POINT_LIST_VIEW,
            consts.GIFT_LIST_VIEW,
            consts.BADGE_LIST_VIEW
          ],
          items: [
            { to: { name: 'RedeemCodeBundleList' }, text: '優惠券管理', checkPermission: [consts.REDEEM_CODE_LIST_VIEW] },
            { to: { name: 'PointList' }, text: '點數管理', checkPermission: [consts.POINT_LIST_VIEW] },
            { to: { name: 'GiftList' }, text: '贈品管理', checkPermission: [consts.GIFT_LIST_VIEW] },
            { to: { name: 'BadgeList' }, text: '徽章管理', checkPermission: [consts.BADGE_LIST_VIEW] },
          ]
        },
        {
          key: "sidebar-auto-module",
          text: "自動化模組",
          icon: 'wand-magic-sparkles-solid.svg',
          order: 450,
          checkPermissionAny: [
            consts.MEMBER_EVENT_LIST_VIEW,
            consts.ACTION_EVENT_LIST_VIEW,
            consts.SHOPPING_EVENT_LIST_VIEW,
            consts.CUSTOMIZE_EVENT_LIST_VIEW,
            consts.MKT_CONFIG_LIST_VIEW],
          items: [
            { to: { name: 'AutoMarketingMemberEvent' }, text: '會員事件設定', checkPermission: [consts.MEMBER_EVENT_LIST_VIEW] },
            { to: '#', text: '活動事件設定', checkPermission: [consts.ACTION_EVENT_LIST_VIEW] },
            { to: '#', text: '購物事件設定', checkPermission: [consts.SHOPPING_EVENT_LIST_VIEW] },
            { to: '#', text: '自定義事件', checkPermission: [consts.CUSTOMIZE_EVENT_LIST_VIEW] },
            { to: { name: 'AutoMarketingList' }, text: '行銷自動化', checkPermission: [consts.MKT_CONFIG_LIST_VIEW] },
          ]
        },
        {
          key: "sidebar-marketing",
          text: "行銷應用中心",
          icon: 'crosshairs-solid.svg',
          order: 500,
          checkPermissionAny: [
            consts.SHORT_URL_VIEW,
            consts.REWARD_CARD_LIST_VIEW,
          ],
          items: [
            { to: { name: 'ShortUrlList' }, text: '短網址管理', checkPermission: [consts.SHORT_URL_VIEW] },
            { to: { name: 'RewardCardList' }, text: '集點卡管理', checkPermission: [consts.REWARD_CARD_LIST_VIEW] },
          ]
        },
        {
          key: "sidebar-orders",
          text: "銷售應用中心",
          icon: 'store-solid.svg',
          order: 550,
          checkPermissionAny: [
            consts.ORDER_LIST_VIEW,
            consts.PRODUCT_LIST_VIEW,
            consts.BRANCH_PRODUCT_LIST_VIEW,
            consts.BRANCH_PRODUCT_STOCK_LIST_VIEW,
            consts.PREORDER_SETTING_LIST,
          ],
          items: [
            { to: { name: 'OrderList' }, text: '訂單管理', checkPermission: [consts.ORDER_LIST_VIEW] },
            { to: { name: 'ProductList' }, text: '商品管理', checkPermission: [consts.PRODUCT_LIST_VIEW] },
            { to: { name: 'BranchProductList' }, text: '分店商品列表', checkPermission: [consts.BRANCH_PRODUCT_LIST_VIEW] },
            { to: { name: 'BranchProductStockList' }, text: '分店庫存管理', checkPermission: [consts.BRANCH_PRODUCT_STOCK_LIST_VIEW] },
            { to: { name: 'PreorderSettingList' }, text: '預購單設定檔管理', checkPermission: [consts.PREORDER_SETTING_LIST] },
          ]
        },
        {
          key: "sidebar-content",
          text: "內容管理中心",
          icon: 'book-solid.svg',
          order: 600,
          checkPermissionAny: [
            consts.POST_LIST_VIEW,
            consts.FILE_LIST_VIEW,
            consts.FORM_LIST,
            consts.NOTIFICATION_LIST,
            consts.EVENT_LIST_VIEW,
            consts.COLLECTION_LIST,
          ],
          items: [
            { to: { name: 'PostList' }, text: '文章管理', checkPermission: [consts.POST_LIST_VIEW] },
            { to: { name: 'UploadFileList' }, text: '檔案管理', checkPermission: [consts.FILE_LIST_VIEW] },
            { to: { name: 'FormList' }, text: '表單管理', checkPermission: [consts.FORM_LIST] },
            { to: { name: 'NotificationList' }, text: '通知管理', checkPermission: [consts.NOTIFICATION_LIST] },
            { to: { name: 'EventList' }, text: '活動管理', checkPermission: [consts.EVENT_LIST_VIEW] },
            { to: { name: 'CollectionList' }, text: '資料集列表', checkPermission: [consts.COLLECTION_LIST] },
          ]
        },
        {
          key: "sidebar-bookings",
          text: "登記預約中心",
          icon: 'calendar-solid.svg',
          order: 650,
          checkPermissionAny: [
            consts.BOOKING_CALENDAR,
            consts.BOOKING_LIST_VIEW,
            consts.BOOKING_LIST,
          ],
          items: this.getBookingPresetsItems(),
          // items: [
          //   { to: { name: "BookingSelectList" }, text: "預約管理", checkPermission: [consts.BOOKING_LIST] },
          //   { to: { name: "BookingPresetList" }, text: "預約設定檔列表", checkPermission: [consts.BOOKING_PRESET_LIST] },
          // ]
        },
        {
          key: "sidebar-booking-presets",
          text: "預約參數設定",
          icon: "calendar-solid.svg",
          order: 700,
          checkPermissionAny: [
            consts.BOOKING_PRESET_LIST,
          ],
          items: [
            { to: { name: "BookingPresetList" }, text: "預約設定檔列表", checkPermission: [consts.BOOKING_PRESET_LIST] },
          ],
        },
        {
          key: "sidebar-invoice",
          text: "發票模組",
          icon: 'receipt-solid.svg',
          order: 750,
          checkPermissionAny: [
            consts.INVOICE_LIST_VIEW,
            consts.INVOICE_LOG_LIST_VIEW,
            consts.INVOICE_CARRIER_VIEW,
          ],
          items: [
            { to: { name: 'InvoiceList' }, text: '發票列表', checkPermission: [consts.INVOICE_LIST_VIEW] },
            { to: { name: 'InvoiceLogList' }, text: '登錄發票列表', checkPermission: [consts.INVOICE_LOG_LIST_VIEW] },
            { to: { name: 'InvoiceCarrierList' }, text: '載具登錄列表', checkPermission: [consts.INVOICE_CARRIER_VIEW] },
          ]
        },
        {
          key: "sidebar-control",
          text: "控制中心",
          icon: 'gears-solid.svg',
          order: 800,
          checkPermissionAny: [
            consts.STAFF_LIST_VIEW,
            consts.BRANCH_LIST_VIEW,
            consts.POINT_RULE_VIEW,
            consts.DAILY_ACT_LIST_VIEW,
            consts.MKT_RULE_LIST_VIEW,
          ],
          items: [
            { to: { name: 'StaffList' }, text: '帳號管理', checkPermission: [consts.STAFF_LIST_VIEW] },
            { to: { name: 'Branches' }, text: '分店管理', checkPermission: [consts.BRANCH_LIST_VIEW] },
            { to: { name: 'PointRuleList' }, text: '入點比例管理', checkPermission: [consts.POINT_RULE_VIEW] },
            { to: { name: 'DailyActivityList' }, text: '每日活動管理', checkPermission: [consts.DAILY_ACT_LIST_VIEW] },
            { to: { name: 'MarketingRuleList' }, text: '行銷規則管理', checkPermission: [consts.MKT_RULE_LIST_VIEW] },
          ]
        },
        {
          key: "sidebar-poya-groupbuyings",
          text: "分店團購管理",
          icon: 'cart-shopping-solid.svg',
          order: 900,
          checkPermissionAny: [
            consts.GROUPBUYING_LIST_VIEW,
            consts.SELECTION_GROUPBUYING_CREATE,
            consts.STORE_GROUPBUYING_CREATE,
            consts.GROUPBUYING_BLACKLIST,
          ],
          items: [
            { to: { name: 'GroupbuyingsList', query: { tab: 'going' } }, text: '團購列表', checkPermission: [consts.GROUPBUYING_LIST_VIEW] },
            { to: { name: 'StoreGroupbuyingScanProduct' }, text: '門市庫存品開團', checkPermission: [consts.STORE_GROUPBUYING_CREATE] },
            { to: { name: 'GroupbuyingsCreate' }, text: '總公司選品開團', checkPermission: [consts.SELECTION_GROUPBUYING_CREATE] },
            { to: { name: 'BlacklistList', params: { branchID: this.selectedBranch } }, text: '逾期未取名單', checkPermission: [consts.GROUPBUYING_BLACKLIST] },
          ]
        },
        {
          key: "sidebar-poya-groupbuyingsMain",
          text: "總公司團購管理",
          icon: 'cart-shopping-solid.svg',
          order: 910,
          checkPermissionAny: [
            consts.POYA_AUTOAWARD_VIEW,
            consts.MAIN_GROUPBUYING_LIST_VIEW,
            consts.MAIN_STORE_GROUPBUYING_CREATE,
            consts.MAIN_GROUPBUYING_CREATE,
            consts.EVENT_PRODUCT_LIST_VIEW,
            consts.THEME_GROUPBUYING_LIST_VIEW,
            consts.GROUPBUYING_SKU_QTY_UPDATE,
            consts.POYA_GROUPBUYINGORDER_SEARCH,
          ],
          items: [
            { to: { name: 'AutoawardList' }, text: '自動派獎設定', checkPermissionAny: [consts.POYA_AUTOAWARD_VIEW] },
            { to: { name: 'MainGroupbuyingsList', query: { tab: 'going' } }, text: '總公司團購列表', checkPermission: [consts.MAIN_GROUPBUYING_LIST_VIEW] },
            { to: { name: 'MainGroupbuyingScanProduct' }, text: '門市庫存品開團', checkPermission: [consts.MAIN_STORE_GROUPBUYING_CREATE] },
            { to: { name: 'MainGroupbuyingsCreate' }, text: '總公司選品開團', checkPermission: [consts.MAIN_GROUPBUYING_CREATE] },
            { to: { name: 'MainGroupbuyingsBatch' }, text: '下單數量設定', checkPermission: [consts.GROUPBUYING_SKU_QTY_UPDATE] },
            { to: { name: 'ProductWhiteList' }, text: '商品白名單設定', checkPermission: [consts.EVENT_PRODUCT_LIST_VIEW] },
            { to: { name: 'ThemeGroupbuyingList' }, text: '主題團購列表', checkPermission: [consts.THEME_GROUPBUYING_LIST_VIEW] },
            { to: { name: 'PoyaGroupbuyingOrderList' }, text: '顧客訂單查詢', checkPermission: [consts.POYA_GROUPBUYINGORDER_SEARCH] },
          ]
        },
        {
          key: "sidebar-poya-groups",
          text: "群組管理",
          icon: 'users-solid.svg',
          order: 920,
          checkPermissionAny: [
            consts.GROUP_LIST_VIEW_1,
            consts.GROUP_LIST_VIEW_2,
            consts.GROUP_LIST_VIEW_3
          ],
          items: [
            {
              to: { name: 'GroupList' }, text: '群組列表', checkPermissionAny: [consts.GROUP_LIST_VIEW_1,
              consts.GROUP_LIST_VIEW_2,
              consts.GROUP_LIST_VIEW_3]
            },
          ]
        },
        {
          key: "sidebar-poya-group-protect",
          text: "群組保護",
          icon: 'user-shield-solid.svg',
          order: 930,
          checkPermissionAny: [
            consts.GUARD_GROUP_LIST_VIEW,
            consts.GUARD_BLACK_GLOBAL_MODIFY,
            consts.GUARD_GROUP_SETTING_LIST_VIEW,
            consts.GUARD_MEMBER_LIST_VIEW,
            consts.GUARD_REPORT_LIST_VIEW,
            consts.GROUP_MESSAGE_CREATE
          ],
          items: [
            { to: { name: 'GroupProtectMembers' }, text: '成員名單設定', checkPermission: [consts.GUARD_MEMBER_LIST_VIEW] },
            { to: { name: 'GroupProtectBlackMembers' }, text: '廣告黑名單(全店)管理', checkPermission: [consts.GUARD_BLACK_GLOBAL_MODIFY] },
            { to: { name: 'GroupProtectSetting' }, text: '群組保護設定', checkPermission: [consts.GUARD_GROUP_SETTING_LIST_VIEW] },
            { to: { name: 'GuardGroups' }, text: '衛兵群組列表', checkPermission: [consts.GUARD_GROUP_LIST_VIEW] },
            { to: { name: 'GroupProtectGuards' }, text: '衛兵狀態列表', checkPermission: [consts.GUARD_REPORT_LIST_VIEW] },
            { to: { name: 'GroupMessageCreate' }, text: '群組廣播', checkPermission: [consts.GROUP_MESSAGE_CREATE] },
          ]
        },
        {
          key: "sidebar-params-settings",
          text: "參數設定",
          icon: 'sliders-solid.svg',
          order: 940,
          checkPermissionAny: [
            consts.SYSTEM_INFO,
            consts.LINE_SETTING_MODIFY,
            consts.SHOPLINE_SETTING_MODIFY,
            consts.LIFF_CARD_SETTING_MODIFY,
            consts.LIFF_UI_SETTING_MODIFY,
            consts.TAKOPOS_SETTING_MODIFY,
            consts.POSPAL_SETTING_MODIFY,
          ],
          items: [
            { to: { name: 'LiffPageList' }, text: '系統資訊', checkPermission: [consts.SYSTEM_INFO] },
            { to: { name: 'LineSetting' }, text: 'LINE 參數設定', checkPermission: [consts.LINE_SETTING_MODIFY] },
            { to: { name: 'OrganizationVariableConfigByCode', params: {organization_variable_id: "2DFIRE-CONFIG"}}, text: 'TAKOPOS 設定', checkPermission: [consts.TAKOPOS_SETTING_MODIFY] },
            { to: { name: 'OrganizationVariableConfigByCode', params: {organization_variable_id: "POSPAL-CONFIG"}}, text: '瑞乘POS 設定', checkPermission: [consts.POSPAL_SETTING_MODIFY] },
            { to: { name: 'OrganizationVariableConfigByCode', params: {organization_variable_id: "SHOPLINE-CONFIG"} }, text: 'SHOPLINE 設定', checkPermission: [consts.SHOPLINE_SETTING_MODIFY] },
            { to: { name: 'LiffCardSetting' }, text: '會員卡畫面', checkPermission: [consts.LIFF_CARD_SETTING_MODIFY] },
            { to: { name: 'LiffUISetting' }, text: '會員介面設定', checkPermission: [consts.LIFF_UI_SETTING_MODIFY] },
          ]
        },
        {
          key: "sidebar-org-settings",
          text: "組織資料中心",
          icon: 'building-shield-solid.svg',
          order: 950,
          checkPermissionAny: [
            consts.ORGANIZATION_VARIABLE,
            consts.ROLE_LIST_VIEW,
            consts.MODULE_LIST_VIEW,
            consts.KEYWORD_LIST_VIEW,
            consts.SQL_RUNNER_VIEW,
            consts.MERCHANT_LIST_VIEW,
            consts.LOGS_LIST_VIEW,
            consts.TOKEN_LIST_VIEW,
          ],
          items: [
            {
              to: { name: 'OrganizationHome' }, text: '組織管理', checkPermissionAny: [
                consts.ORGANIZATION_VARIABLE,
                consts.ROLE_LIST_VIEW,
                consts.MODULE_LIST_VIEW,
                consts.KEYWORD_LIST_VIEW,
                consts.SQL_RUNNER_VIEW,
                consts.MERCHANT_LIST_VIEW,
                consts.LOGS_LIST_VIEW,
                consts.TOKEN_LIST_VIEW
              ]
            },
            { to: { name: 'OrganizationVariableList' }, text: '參數管理', checkPermission: [consts.ORGANIZATION_VARIABLE] },
            { to: { name: 'ModuleList', params: { orgId: this.organization?.id } }, text: '模組管理', permissions: [consts.MODULE_LIST_VIEW] },
            { to: { name: 'RoleList' }, text: '權限管理', checkPermission: [consts.ROLE_LIST_VIEW] },
            { to: { name: 'SuiteList' }, text: '方案管理', checkPermission: [consts.SUITE_SETTING] },
            { to: { name: 'KeywordList' }, text: '關鍵字管理', checkPermission: [consts.KEYWORD_LIST_VIEW] },
            { to: { name: 'ServerLogList' }, text: '系統紀錄', checkPermission: [consts.LOGS_LIST_VIEW] },
            { to: { name: 'SqlRunnerList' }, text: 'SQL 排程管理', checkPermission: [consts.SQL_RUNNER_VIEW] },
            { to: { name: 'TokenList' }, text: 'API TOKEN 管理', checkPermission: [consts.TOKEN_LIST_VIEW] },
          ]
        },
        {
          key: "sidebar-system-admin-settings",
          text: "全系統設定",
          icon: 'city-solid.svg',
          order: 999,
          checkPermission: [consts.ORGANIZATION_LIST_VIEW],
          items: [
            { to: { name: 'OrganizationList' }, text: '組織管理' },
          ]
        }
      ];
    },

  },
  methods: {
    displayText,
    ...mapActions('general', ['setCurrentBranch', 'setCurrentOrg', 'fetchBranches']),
    async init() {
      if (!this.currentBranch) {
        if (this.myBranches.length) {
          this.selectedBranch = this.myBranches[0].id ?? null;
          this.switchBranch();
        }
      } else {
        this.selectedBranch = this.currentBranch.id;
      }
      if (!this.selectedOrganization) {
        this.selectedOrganization = this.organization.id
      }

      // 如果擁有查看預約管理中心權限，就撈取 bookingPresets
      if (this.checkPermission([consts.BOOKING_LIST_VIEW])) {
        await this.fetchBookingPresets();
      }
    },

    async switchBranch() {
      let _this = this
      _this.disableSelect = true
      await this.setCurrentBranch(this.selectedBranch).then(() => {
        this.fetchBranches();
        this.$nextTick(() => {
          _this.$router.go(0)
        })
      }).finally(() => {
        _this.disableSelect = false
      })
    },
    async switchOrganization() {
      let _this = this;
      _this.disableSelect = true
      await this.setCurrentOrg(this.selectedOrganization).then(() => {
        this.selectedBranch = (this.currentBranch) ? this.currentBranch.id : null
        _this.$router.replace({ name: 'DashboardHome', params: { org_code: this.organization.code } })
      }).finally(() => {
        _this.disableSelect = false
      })
    },
    updateView() {
      // force router-view update with router key
      this.$emit('updateView')
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    checkPermissionAny(permissions) {
      const checker = new PermissionChecker;
      return checker.checkAny(permissions);
    },
    anyPermissions(permissions) {
      let result = false;
      const checker = new PermissionChecker;
      permissions.forEach(permission => {
        if (checker.check([permission])) {
          result = true;
        }
      });
      return result;
    },
    sidebarOrder(key) {
      return this.dashboardModule.sidebar?.find(x => x.key == key)?.order
    },
    subMenuVisible(i) {
      try{
        return i.items.some((x) =>
            (x.to.name == this.$route.name) ||
          (typeof x.to === 'object' &&
            this.$route.path.split('/')[3]==this.$router.resolve({ name: x.to.name }).resolved.path.split('/')[3]))
      } catch{
        return false
      }
    },
    subMenuActive(j) {
      let resolvedRoute = this.$router.resolve({ name: j.to.name, params: j.to.params }).resolved;
      let subMenuPath = resolvedRoute.path;

      // 判斷路徑和參數是否匹配
      let isPathMatching = subMenuPath !== '/' && typeof j.to === 'object' && this.$route.path.startsWith(subMenuPath);
      let areParamsMatching = JSON.stringify(this.$route.params) === JSON.stringify(j.to.params);

      return isPathMatching && areParamsMatching;
    },
    async fetchBookingPresets() {
      const response = await bookingApi.getBookingPresets({
        per_page: 100,
        is_enabled: true
      });

      this.bookingPresets = response.data.data;
    },
    getBookingPresetsItems() {
      let items = [
        { to: { name: "BookingSelectList" }, text: "預約管理", checkPermissionAny: [consts.BOOKING_LIST] },
        { to: { name: 'BookingCenterCalendar' }, text: '預約日曆', checkPermission: [consts.BOOKING_CALENDAR] }
      ];

      if (!this.checkPermission([consts.BOOKING_LIST_VIEW])) {
        return items;
      }

      for (let i = 0; i < this.bookingPresets.length; i ++) {
        const bookingPreset = this.bookingPresets[i];
        if (bookingPreset.config?.is_enabled ?? false) {
          items.push({
            to: { name: "BookingCenterBookingList", params: {id: bookingPreset.id} },
            text: bookingPreset.name,
            checkPermission: [consts.BOOKING_LIST_VIEW]
          });
        }
      }

      return items;
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-organization-selector {
  margin-right: 20px;
  margin-left: 20px;
}

// .nav-item .collapse:has(.router-link-exact-active){
//   display: block !important;
// }
</style>
